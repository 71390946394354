import React from "react";

export const Main = () => {
    return (
        <div className="content">
            <p>
                My name is Bartosz and I am a full stack engineer with an interest in machine learning. I have extensive
                experience with .NET, TypeScript, and React, as well as many other languages and frameworks. Please take a
                look through this site for a more detailed picture of my qualifications.
            </p>
        </div>
    );
};