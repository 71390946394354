import React from "react";

export const DownCaret = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="#ecdbba"
        viewBox="0 0 256 256"
    >
        <rect width="256" height="256" fill="none"></rect>
        <polyline
            points="208 96 128 176 48 96"
            fill="none"
            stroke="#ecdbba"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
        ></polyline>
    </svg>
);

export const RightCaret = () => <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="#c84b31" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="96 48 176 128 96 208" fill="none" stroke="#c84b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></polyline></svg>;

export const Hamburger = () => <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="#c84b31" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><line x1="40" y1="128" x2="216" y2="128" stroke="#c84b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></line><line x1="40" y1="64" x2="216" y2="64" stroke="#c84b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></line><line x1="40" y1="192" x2="216" y2="192" stroke="#c84b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></line></svg>;