export const Other = () => {
    return (
        <div className="content">
            <p style={{ textAlign: "center" }}>
                Some other sites I made (just some goofs):
                <br /><br />
                <a className="other-links" href="https://bartkosa.com/keyboardviz/">/keyboardviz/</a>
                <br /><br />
                <a className="other-links" href="https://bartkosa.com/cheeseburger/">/cheeseburger/</a>
            </p>
        </div>
    );
};